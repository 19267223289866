import FileSaver from 'file-saver';
import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getFormData } from '../../../state/formSlice';
import { getInfoData } from '../../../state/infoSlice';
import { ErvFormState } from '../../../types';
import { useMemo, useState } from 'react';
import { postErv } from '../../../api';
import Waiting from './Waiting';

type Status = 'loading' | 'done' | 'initial';

const OverviewStep = () => {
  const form = useSelector(getFormData);
  const info = useSelector(getInfoData);
  const ervData: ErvFormState = useMemo(() => ({ info, form }), [info, form]);
  const [status, setStatus] = useState<Status>('initial');
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const employersCount = useMemo(() => (
    form.companies.reduce((acc, company) => acc + company.employers.length, 0)
  ), [form.companies]);
  return (
    <div className='mgl mgr'>
      <Card>
        <Card.Body>
          <br />
          <Card.Subtitle className="mb-2 text-muted">Ukupno Firmi: {form.companies.length}</Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted">Ukupno Zaposlenika: {employersCount}</Card.Subtitle>
        </Card.Body>
      </Card>
      <Button
        className='mt-4 mb-4'
        disabled={status !== 'initial'}
        onClick={() => {
          setStatus('loading');
          postErv(ervData)
            .then((response) => {
              if (response.ok) {
                // Create a Blob from the response
                const blob = new Blob([response.data as any], { type: 'application/zip' });

                // Use FileSaver.js to trigger download
                FileSaver.saveAs(blob, 'files.zip');
              } else {
                console.error('Download failed:', response.problem);
              }
              setStatus('done');
              if (response.ok && response.data) {
                setDownloadUrl('asa');
              }
            })
        }}
      >Generiraj Erv Dokumente</Button>
      {status === 'loading' && (
        <Waiting count={employersCount} />
      )}
      {status === 'done' && (downloadUrl ? (
        <Card>
          <Card.Body>
            <Card.Text className="mb-2 text-muted">
              Dokumenti su uspješno generirani
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (
        <Card>
          <Card.Body>
            <Card.Text className="mb-2 text-muted">
              Greška prilikom generiranja dokumenta
            </Card.Text>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default OverviewStep;
